import React, {useEffect} from 'react';
import {navigate} from 'gatsby';

const BlackFridayPage = () => {
  useEffect(() => {
    navigate('/pricing/');
  }, []);

  return (
    <></>
  );
};

export default BlackFridayPage;
